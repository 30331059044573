/** @jsx jsx */
import { jsx } from "theme-ui"

import { graphql } from "gatsby"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Listing from "../components/Listing"

const Index = ({ data: { homepage, posts } }) => (
  <Layout>
    <SEO title="Brand Strategist" />
    <Listing posts={posts.nodes} />
  </Layout>
)

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      nodes {
        uid
        data {
          date
          thumbnail {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title {
            html
            text
          }
          quote {
            text
          }
          content {
            html
            text
          }
          categories {
            category {
              document {
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
